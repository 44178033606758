<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Venues</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Venues
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">

                  <v-btn  @click="createOrUpdateVenue()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add venue
                  </v-btn>

                  <b-dropdown class="ml-5" size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" 
                    no-caret 
                    right 
                    no-flip
                  >
                    <template v-slot:button-content>
                      <i class="ki ki-bold-more-hor"></i>
                    </template>
                    <!--begin::Navigation-->
                    <div class="navi navi-hover min-w-md-250px">
                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="exportToExcel()" class="navi-link">
                          <span class="navi-icon">
                              <i class="fa fa-file-excel"></i>
                          </span>
                          <span class="navi-text">Export (excel)</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item">
                        <a class="navi-link" @click="exportToCsv()">
                          <span class="navi-icon">
                              <i class="fa fa-file-csv"></i>
                          </span>
                          <span class="navi-text">Export (CSV)</span>
                        </a>
                      </b-dropdown-text>
                    </div>
                  </b-dropdown>

                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="2" v-if="currentUser.access_type!='score'">
                  <v-select :items="scores"
                      v-on:keyup.enter="searchVenue"
                      v-model="search.score_id"
                      label="SCORE"
                      item-text="name"
                      @change="handleScoreChange"
                      item-value="id" :menu-props="{ bottom: true, offsetY: true }"
                      outlined
                      dense clearable>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                      label="Search by venue name or code"
                      clearable
                      v-model="search.info"
                      outlined
                      dense
                      v-on:keyup.enter="searchVenue"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    outlined
                    dense
                    v-model="search.location_id"
                    label="Location"
                    clearable
                    :items="locations"
                    item-text="name"
                    item-value="id"
                    :loading="locationLoading"
                    v-on:keyup.enter="searchVenue"
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-autocomplete
                      label="Host type"  v-on:keyup.enter="searchVenue"
                      outlined :items="types"
                      dense clearable
                      item-value="value"
                      item-text="name" :menu-props="{ bottom: true, offsetY: true }"
                      v-model="search.type">
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6" md="2">
                  <v-autocomplete v-on:keyup.enter="searchVenue"
                            :items="status"
                            v-model="search.is_active"
                            label="Status"
                            item-text="name"
                            item-value="value"  :menu-props="{ bottom: true, offsetY: true }"
                            outlined
                            dense clearable>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="text-right" :md="currentUser.access_type == 'score' ? 2 : 12">
                  <v-btn :loading="isLoading"
                         @click="searchVenue"
                         class="btn btn-primary"
                         style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!isLoading">
                <thead>
                <tr class="px-3">
                  <th style="max-width: 270px !important; white-space: pre-wrap;" class="px-3">Venue</th>
                  <th class="px-3">Details</th>
                  <th class="px-3">Owner</th>
                  <th class="px-3">Location</th>
                  <th class="px-3">Host type</th>
                  <th class="px-3">Host externals</th>
                  <th class="px-3">Code</th>
                  <th class="px-3" style="max-width: 370px !important; white-space: pre-wrap;">SCORE</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-if="venues.length > 0" v-for="item in venues">
                    <td style="max-width: 270px !important; white-space: pre-wrap;" class="px-3">
                      <a @click="createOrUpdateVenue(item.id)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.name }}
                      </a>
                    </td>

                    <td class="px-3">
                      <a href="#!"  class="mb-1" v-if="item.contact_person_full_name">
                        {{item.contact_person_full_name }}
                      </a>
                     <div v-if="item.email">
                       <a class="text-info mb-1" :href="'mailto:'+item.email">
                         {{item.email ? item.email : 'NA' }}
                       </a>
                      </div>
                      <div>
                        <a  class="text-info mb-1" :href="'tel:'+item.phone">
                          {{item.phone}}
                        </a>
                      </div>
                    </td>

                    <td class="px-3">
                      {{item.owner_name? item.owner_name: ''}}
                    </td>
                    <td class="px-2">
                        <span class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
                        {{item.location_name}}
                        </span>
                    </td>

                    <td class="px-2">
                        <span class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
                        {{item.type_text ? item.type_text : 'NA'}}
                        </span>
                    </td>
                    <td>
                      <span class="badge" :class="item.host_other_candidates ? 'badge-success' : 'badge-danger'" v-if="item.type=='private'">
                        {{item.host_other_candidates ? 'Yes' : 'No'}}
                      </span>
                      <span class="badge badge-info" v-else> NA
                      </span>
                    </td>
                    <td class="px-2">
                        <span class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
                        {{item.unique_code}}
                        </span>
                    </td>

                    <td class="px-2" style="max-width: 370px !important; white-space: pre-wrap;">
                        <span class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
                        {{item.score_purchase_prefix}}
                        </span>
                    </td>
                    <td>
                      <div>
                      <span class="badge"
                            v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }"
                      >{{ item.status_text }}</span>
                      </div>
<!--                      <div class="mt-2" v-if="item.is_central_venue">-->
<!--                        <span class="badge badge-primary text-lg`">Central</span>-->
<!--                      </div>-->
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="createOrUpdateVenue(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="venueContact(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-address-card"></i>
                                </span>
                                <span class="navi-text">Venue contacts</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="viewRoom(item.id)" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-street-view"></i>
                                </span>
                                <span class="navi-text">Rooms</span>
                              </a>
                            </b-dropdown-text>

<!--                            <b-dropdown-text tag="div" class="navi-item">-->
<!--                              <a @click="setAsCentral(item.id,item)" class="navi-link">-->
<!--                                <span class="navi-icon">-->
<!--                                  <i class="fas fa-lock" v-if="!item.is_central_venue"></i>-->
<!--                                  <i class="fas unlock" v-else></i>-->

<!--                                </span>-->
<!--                                <span class="navi-text" v-if="!item.is_central_venue">Set as central</span>-->
<!--                                <span class="navi-text" v-else>Unset As central</span>-->
<!--                              </a>-->
<!--                            </b-dropdown-text>-->
                            <b-dropdown-text tag="div" class="navi-item">
                              <a  @click="deleteVenue(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="venues.length == 0">
                    <td colspan="7" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="venues.length > 0"
                  class="pull-right mt-7"
                  @input="getAllVenue"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="isLoading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import VenueService from "@/services/practical-exam/venue/VenueService";
import ScoreService from "@/services/score/score/ScoreService";
import LocationService from "@/services/practical-exam/location/LocationService";

const venue=new VenueService();
const score = new ScoreService();
const location = new LocationService();


export default {
  data(){
    return{
      isLoading:false,
      search:{
        info:'',
        is_active:'',
        score_id:'',
        location_id: null,
        type:'',
      },
      status: [
        {name: 'Active', value: 1},
        {name: 'Inactive', value: 0},
      ],
      types: [
        {name: 'Public',value:'state'},
        {name: 'Private',value:'private'},
        {name: 'Unspecified', value: 'none'},

      ],
      venues:[],
      total: null,
      perPage: null,
      page: null,
      scores:[],
      currentUser:{},
      locationLoading: false,
      locations: []
    }
  },
  methods:{
    createOrUpdateVenue(venueId){
      if(venueId==null || venueId==undefined)
        this.$router.push({
          name:"venue-create",
        });
      else
        this.$router.push({
          name:'venue-edit',
          params:{venueId:venueId}
        })
    },
    venueContact(venueId) {
      this.$router.push({
        name:'venue-contact',
        params: { venueId: venueId }
      })
    },
    viewRoom(venueId){
      this.$router.push({
        name: 'room',
        params: { venueId: venueId }
      });
    },
    getAllVenue(){
      this.isLoading=true;
      venue
          .paginate(this.search,this.page)
          .then(response => {
            this.venues=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {
          })
          .finally(() => {
            this.isLoading=false;
          });
    },
    searchVenue(){
      this.getAllVenue();
    },
    setAsCentral(venueId,item){

      venue
          .setAsCentral(venueId,{'central_status':item.is_central_venue})
          .then(response => {
            this.getAllVenue();
            this.$snotify.success("Venue Set as Central Successfully ");
          })
          .catch((err) => {

       }).finally(() => {
            this.isLoading=false;
          });
    },
    deleteVenue(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            venue
                .delete(item)
                .then((response) => {
                  this.getAllVenue();
                  this.$snotify.success("Venue deleted");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    getAllScore(){
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
            if(this.currentUser.access_type == 'score'){
              this.getLocation(this.currentUser.score_id);
            }
          })
          .catch((err) => {

          });
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },
    handleScoreChange(){
      this.search.location_id = null;
      
      if(this.search.score_id){
        this.getLocation(this.search.score_id);
      }else{
        this.locations = [];
      }
    },
    getLocation(scoreId){
      this.locationLoading = true;

      location
      .getByScore(scoreId)
      .then((res) => {  
        this.locations = res.data;
      })
      .catch((err) => {

      })
      .finally(() => {
        this.locationLoading = false;
      })
    },
    exportToCsv(data){
      venue
      .exportToCsv(this.search);
    },

    exportToExcel(data){
      venue
      .exportToExcel(this.search);
    }
  },
  mounted() {
    this.getCurrentUser();
    this.getAllVenue();
    this.getAllScore();
  }
}
</script>